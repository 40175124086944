import React, { useContext, useEffect, memo } from 'react';
import { Progress } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Handle, Position } from 'reactflow';
import factory from '../assets/images/factory.svg';
// import charger from '../assets/images/charger.svg';
import home from '../assets/images/home.svg';
import solar from '../assets/images/solar.svg';
import store from '../assets/images/store.svg';
import energy_net from '../assets/images/energy_net.png';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';

export const EnergySource = memo(() => {
    return (
        <div className='node-energySource' style={{backgroundImage:`url(${energy_net})`}}>
            <Handle type="source" position={Position.Left} id="left" style={{top:'40%'}}/>
            <Handle type="target" position={Position.Left} id="tleft" style={{top:'40%'}}/>
            <Handle type="source" position={Position.Left} id="left2" style={{top:'60%'}}/>
            <Handle type="source" position={Position.Right} id="right" style={{top:'40%'}}/>
            <Handle type="source" position={Position.Right} id="right2" style={{top:'60%'}}/>
            <Handle type="target" position={Position.Right} id="tright" style={{top:'40%'}}/>
            <Handle type="target" position={Position.Right} id="tright2" style={{top:'60%'}}/>
            能源網路
        </div>
        )
})
  
export const TaiPowerStation = memo(() => {
    const {time} = useContext(TimeContext);    
    const {loadMonitor,essMonitor,pvMonitor,changeTaiPwoerReverse,taiPowerReverse,changeTaiPwoerZero,taiPowerZero}  = useContext(DataContext);
    const index = Math.floor(time/10);
    const timeIndex = index < 96 ? index-1 : 95;
    const pvTimeIndex = index <= 20 ? -1 : index > 80 ? 59 : index-21;

    const calcTaipower = ()=>{
        const ess = essMonitor && essMonitor[timeIndex] ? essMonitor[timeIndex].powerKW.toFixed(2) : 0;
        const load = loadMonitor && loadMonitor[timeIndex] ? loadMonitor[timeIndex].powerKW.toFixed(2) : 0;
        const pv =  pvMonitor && pvMonitor[pvTimeIndex] ? pvMonitor[pvTimeIndex].powerKW.toFixed(2) : 0;
        return (parseFloat(load) - parseFloat(ess) - parseFloat(pv) ).toFixed(2);
    }
    const taiPower = calcTaipower();

    useEffect(()=>{       
        if(taiPower && taiPower < 0){
            changeTaiPwoerReverse(true);
            changeTaiPwoerZero(false);
        }else if(parseFloat(taiPower) === 0.00){
            changeTaiPwoerZero(true);
        }else{
            changeTaiPwoerReverse(false);
            changeTaiPwoerZero(false);
        }
    },[taiPower,essMonitor,loadMonitor,pvMonitor,time,changeTaiPwoerReverse,changeTaiPwoerZero])

    return (
        <div className='node-all node-taipower'>
            <Handle type="target" position={Position.Right}/>
            <Handle type="source" position={Position.Right}/>
            <img src={factory} alt={factory}/>
            <div className='text'><span className='title'>台電電力主網</span> { taiPowerZero ? '' : taiPowerReverse ? <CaretUpOutlined className='node-secondary-color'/> : <CaretDownOutlined className='node-third-color'/> }<span className={`big ${taiPowerZero ? 'zero':''}`}>{Math.abs(taiPower)}</span> kW</div>
         </div> 
      )
})
  
export const Ess = memo(() => {
    const {time} = useContext(TimeContext); 
    const {essMonitor,changeEssReverse,essReverse,changeEssZero, essZero}  = useContext(DataContext);
    const index = Math.floor(time/10);
    const timeIndex = index < 96 ? index-1 : 95;
    
    useEffect(()=>{
        if(essMonitor && essMonitor[timeIndex] && essMonitor[timeIndex].powerKW.toFixed(2) < 0){
            changeEssReverse(true);
            changeEssZero(false);
        }else if((essMonitor && essMonitor[timeIndex] && parseFloat(essMonitor[timeIndex].powerKW.toFixed(2)) === 0.00) || (essMonitor && essMonitor[timeIndex] === 0)){
            changeEssZero(true);
        }else{
            changeEssReverse(false);
            changeEssZero(false);
        }
    },[essMonitor,time,changeEssReverse,changeEssZero,timeIndex])
    
    return (<div className='node-all node-ess'>
                <Handle type="target" position={Position.Left}/>
                <Handle type="source" position={Position.Left}/>
                <img src={store} alt={store}/>
                <div className='text'>
                <span className='title'>儲能系統</span> {essZero ? '' : essReverse ? <CaretDownOutlined className='node-fourth-color'/> : <CaretUpOutlined className='node-third-color'/> } <span className={`big ${essZero ? 'zero':''}`}>{`${essMonitor && essMonitor[timeIndex] ? Math.abs(essMonitor[timeIndex].powerKW.toFixed(2)) : 0}`}</span> kW <br/>
                    <div style={{width:'80px',margin:'auto'}}>
                        <Progress
                            strokeColor={{
                                '0%': '#009CCD',
                                '100%': '#00CDA8',
                            }}
                            percent={essMonitor && essMonitor[timeIndex] ? Math.round(essMonitor[timeIndex].soc*100) : 0}
                            size="small"
                        />
                    </div>
                </div>
            </div>)
})

export const Solar = memo(() => {
    const {time} = useContext(TimeContext);  
    const {pvMonitor,changePvZero, pvZero}  = useContext(DataContext);
    const index = Math.floor(time/10);
    const pvTimeIndex = index <= 20 ? -1 : index > 80 ? 59 : index-21;

    useEffect(()=>{
        if((pvMonitor && pvMonitor[pvTimeIndex] && parseFloat(pvMonitor[pvTimeIndex].powerKW.toFixed(2)) === 0.00) || (pvMonitor && pvMonitor[pvTimeIndex] === undefined)){
            changePvZero(true);
        }else{
            changePvZero(false);
        }
    },[changePvZero,pvMonitor,pvTimeIndex])
    return (<div className='node-all node-solar'>
        <Handle type="target" position={Position.Left}/>
        <Handle type="source" position={Position.Left}/>
        <img src={solar} alt={solar}/>
        <div className='text'><span className='title'>太陽能發電</span> {pvZero ? '' : <CaretDownOutlined className='node-third-color'/>} <span className={`big ${pvZero ? 'zero':''}`}>{`${pvMonitor && pvMonitor[pvTimeIndex] ? pvMonitor[pvTimeIndex].powerKW.toFixed(2) : '0.00'}`}</span> kW</div>
        </div>)
})

// export function Charger (){
//     const {time} = useContext(TimeContext);  
//     return (<div className='node-all'>
//         <img src={charger} alt={charger}/>
//         <div className='text'>充電樁 <span className='big'>{`${Math.floor(5*(time / 100))}`}</span> kW</div>
//         </div>)
// }

export const Load = memo(() => {
    const {time} = useContext(TimeContext);  
    const {loadMonitor}  = useContext(DataContext);
    const index = Math.floor(time/10);
    const timeIndex = index < 96 ? index-1 : 95;

    return (<div className='node-all node-load'>
            <Handle type="target" position={Position.Right}/>
            <Handle type="source" position={Position.Right}/>
            <img src={home} alt={home}/>
            <div className='text'><span className='title'>用電負載</span> <CaretDownOutlined className='node-fourth-color'/> <span className='big'>{`${loadMonitor && loadMonitor[timeIndex] ? loadMonitor[timeIndex].powerKW.toFixed(2) : 0}`}</span> kW</div>
        </div>)
});