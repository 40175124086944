import React, { useContext } from 'react';
import { ComposedChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip } from 'recharts';
import { DataContext } from '../context/DataProvider';
import { objTimeChangeToMinutes, maxNum1, timeArr, labelFormatter, renderTipFormatter, tickFormatter } from '../fuctionTool/functionTool';

const LoadChart = () => {
    // provider
    const {vppContract}  = useContext(DataContext);
    const vppContract_new = vppContract && objTimeChangeToMinutes(vppContract);

    const max = maxNum1(vppContract_new,"fitPrice"); //計算最高值
    return (
        <ResponsiveContainer width="100%" height={120}>
            <ComposedChart  width={200} height={120} data={vppContract_new} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="fitPrice" fill="#009CCD" stroke="#009CCD" animationDuration={200}/>                
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis type="number" domain={[0,max]}/>
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default LoadChart
