import React, { useContext } from 'react';
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip } from 'recharts';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import { pvData1, pvData2 } from '../pvdata/pvData';
import { pvPredictData1, pvPredictData2 } from '../pvdata/pvPredictData';
import { drawCharts2, objTimeChangeToMinutes, merge2Charts, maxNum, timeArr, labelFormatter, renderTipFormatter, tickFormatter } from '../fuctionTool/functionTool';



const PvChart = () => {
    // provider
    const {time} = useContext(TimeContext);
    const {pvMonitor, pvPredict}  = useContext(DataContext);

    const pvMonitor_new = pvMonitor && [...pvData1, ...objTimeChangeToMinutes(pvMonitor), ...pvData2];
    const pvPredict_new = pvPredict && [...pvPredictData1, ...objTimeChangeToMinutes(pvPredict), ...pvPredictData2];
    
    const mergePv = merge2Charts(pvMonitor_new,pvPredict_new,"pvMonitor","pvPredict");  //合併圖表
    const max = maxNum(mergePv,"pvMonitor","pvPredict"); //計算最高值

    return (
        <ResponsiveContainer width="100%" height={120}>
            <ComposedChart  width={200} height={120} data={mergePv && drawCharts2(mergePv,time,"pvMonitor")} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="pvMonitor" fill="#009CCD" stroke="#009CCD" animationDuration={200}/>
                <Line dot={false} strokeDasharray="3 3" strokeWidth={2} animationDuration={200} type="monotone" dataKey="pvPredict" stroke="#00cda8" />
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis type="number" domain={[0,max]} />
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default PvChart
