import React, { useState, useEffect, useRef, useContext } from 'react';
import {EnergySource ,TaiPowerStation, Ess, Solar, Load} from './Nodes';
import ReactFlow , { Background, useNodesState, useEdgesState, MarkerType }from 'reactflow';
import 'reactflow/dist/style.css';
import { DataContext } from '../context/DataProvider';
// import insertCss from 'insert-css'

//容器寬度
const containerWidth = 906;
const nodeTypes = { energySource: EnergySource, taiPower: TaiPowerStation, ess: Ess, solar: Solar, load: Load };

const allNodes = (width,loadMonitor) =>{
  let allNodes = [
      {
        id: 'energySource',
        position: { x: 355 * (width/containerWidth), y: 86 },
        type: 'energySource'
      },
      {
        id: 'taiPower',
        position: { x: 60 * (width/containerWidth), y: 10 },
        type: 'taiPower'
      },
      {
        id: 'solar',
        position: { x: 700 * (width/containerWidth), y: 10 },
        type: 'solar'
      },
      {
        id: 'ess',
        position: { x: 710 * (width/containerWidth), y: 185 },
        type: 'ess'
      }      
    ];

  if(loadMonitor && loadMonitor.length > 0){
    allNodes.push({
      id: 'load',
      position: { x: 60 * (width/containerWidth), y: 185 },
      type: 'load'
    })
  }
  
  return allNodes
}

const allEdges = (tai,ess,loadMonitor,taiPowerZero,essZero,pvZero) =>{    
  let allEdges = [
    { 
      id: 'energy-taipower', 
      source: tai ? 'energySource' : 'taiPower', 
      target: tai ? 'taiPower' : 'energySource', 
      animated: taiPowerZero ? false : true,          
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: taiPowerZero ? 0:20,
        height: taiPowerZero ? 0:20,
        color: tai ? '#00cda8' : '#008CB5',
      }, 
      style: {
        strokeWidth: 2,
        stroke: taiPowerZero ? '#545e68' : tai ? '#00cda8' : '#008CB5',
      },
      sourceHandle: 'left',
      targetHandle: 'tleft'
    },
    { 
      id: 'energy-solar', 
      source: 'solar', 
      target: 'energySource', 
      animated: pvZero ? false : true, 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: pvZero ? 0 : 20,
        height: pvZero ? 0 : 20,
        color: '#00cda8',
      }, 
      style: {
        strokeWidth: 2,
        stroke: pvZero ? '#545e68' :'#00cda8',
      },
      sourceHandle: 'right',
      targetHandle: 'tright',
    },    
    { 
      id: 'energy-ess', 
      source: ess ? 'energySource' : 'ess', 
      target: ess ? 'ess' : 'energySource', 
      animated: essZero ? false : true, 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: essZero ? 0 : 20,
        height: essZero ? 0 : 20,
        color: ess ?  '#008CB5' : '#00cda8',
      }, 
      style: {
        strokeWidth: 2,
        stroke: essZero ? '#545e68' : ess ? '#008CB5' : '#00cda8',
      },
      sourceHandle: 'right2',
      targetHandle: 'tright2',
    }    
  ];
  if(loadMonitor && loadMonitor.length > 0){    
    allEdges.push({ 
      id: 'energy-load', 
      source: 'energySource', 
      target: 'load', 
      animated: true, 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#008CB5',
      }, 
      style: {
        strokeWidth: 2,
        stroke: '#008CB5',
      },
      sourceHandle: 'left2',
    })
  }  
  return allEdges;
}
// component Process
export default function Process () {
    const container = useRef(null)
    const [width,setWidth] = useState(1);
    const {loadMonitor,taiPowerReverse,essReverse,taiPowerZero,essZero,pvZero} = useContext(DataContext);
    const resizeUpdate = (e) =>{
      let w = container.current.offsetWidth;
      setWidth(w);
    }
    
    const [nodes, setNodes, onNodesChange] = useNodesState(allNodes(width,loadMonitor));
    const [edges, setEdges, onEdgesChange] = useEdgesState(allEdges(taiPowerReverse,essReverse,loadMonitor,taiPowerZero,essZero,pvZero));
    
    // 動畫速度
    // insertCss(`
    //   .react-flow__edge.animated path {
    //     stroke-dasharray: 5;
    //     animation: dashdraw ${ isRunning && runTime === 100 ? '0.4s' : isRunning && runTime === 75 ? '0.3s' : isRunning && runTime === 50 ? '0.2s' : '0.5s' } linear infinite;
    //   }
    // `)
    //useEffect
    useEffect(()=> {      
      window.addEventListener('resize',resizeUpdate);  
      resizeUpdate();       
      if(width > 0){
        setNodes(allNodes(width,loadMonitor));
        setEdges(allEdges(taiPowerReverse,essReverse,loadMonitor,taiPowerZero,essZero,pvZero));  
      }      
      return () =>{
        window.removeEventListener('resize',resizeUpdate)
      }
    },[width,loadMonitor,setNodes,setEdges,taiPowerReverse,essReverse,taiPowerZero,essZero,pvZero])
  
    return (
      <>
        <div className='App-main-kv-charts' ref={container}>
          <ReactFlow 
            nodes={nodes} 
            edges={edges} 
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            maxZoom='1'
            minZoom='1'
            panOnDrag={false}
          >
          <Background color="rgba(255,255,255,0.4)" gap={16}/>
        </ReactFlow>
      </div>
      </>
    )
  }