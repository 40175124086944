import React, { useContext } from 'react';
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip } from 'recharts';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import { drawCharts, objTimeChangeToMinutes, mergeEssCharts, maxNum, minNum, timeArr, labelFormatter, renderTipFormatter, tickFormatter } from '../fuctionTool/functionTool';

const EssChart = () => {
    // provider
    const {time} = useContext(TimeContext);
    const {essMonitor, essPredict}  = useContext(DataContext);

    const essMonitor_new = essMonitor && objTimeChangeToMinutes(essMonitor);
    const essPredict_new = essPredict && objTimeChangeToMinutes(essPredict);

    const mergeEss = mergeEssCharts(essMonitor_new,essPredict_new);  //合併圖表
    const max = maxNum(mergeEss,"essMonitor","essPredict"); //計算最高值
    const min = minNum(mergeEss,"essMonitor","essPredict"); //計算最低值

    return (
        <ResponsiveContainer width="100%" height={120}>
            <ComposedChart  width={200} height={120} data={mergeEss && drawCharts(mergeEss,time)} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="essMonitor" fill="#009CCD" stroke="#009CCD" yAxisId="left" animationDuration={200}/>
                <Line dot={false} strokeWidth={2} yAxisId="right" animationDuration={200} type="monotone" dataKey="soc" stroke="#69ccdb" />
                <Line dot={false} strokeDasharray="3 3" strokeWidth={2} yAxisId="left" animationDuration={200} type="monotone" dataKey="essPredict" stroke="#00cda8" />
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis yAxisId="left" type="number" domain={[min,max]} ticks={[min,0,max]}/>
                <YAxis yAxisId="right" orientation="right" domain={[0,1]} tickFormatter={val => val*100 + "%"}/>
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
    
}

export default EssChart
