import React, { useContext, useEffect, useState } from 'react';
import { Button, Select, Form } from 'antd';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import logo from '../assets/images/logo.svg';
import { useQuery } from '@apollo/client';
import { addDay } from '../fuctionTool/functionTool';
import {GET_SCENE,GET_PV,GET_LOAD,GET_TARGET,GET_ESS,GET_VPP,GET_PV_PREDICT,GET_PV_MONITOR,GET_LOAD_PREDICT,GET_LOAD_MONITOR,GET_ESS_PREDICT,GET_ESS_MONITOR,GET_VPP_TARGET,GET_VPP_EXPECT,GET_VPP_CONTRACT} from '../query/query.js';

//ant option
const { Option } = Select;

const Header =React.memo(() => {
    //state
    const [isShow, setIsShow] = useState('');
    const [sceneItem, setSceneItem] = useState();
    const [pvItem, setPvItem] = useState();
    const [loadItem, setLoadItem] = useState();
    const [essItem, setEssItem] = useState();
    const [targetItem, setTargetItem] = useState();
    const [menuVisible, setMenuVisible] = useState(false);

    //state 選單監聽
    const [sceneType, setSceneType] = useState(0);   
    const [pvType, setPvType] = useState("");  
    const [loadType, setLoadType] = useState("");     

    //context   
    const timeControl  = useContext(TimeContext);
    const dataControl  = useContext(DataContext);
    
    //form
    const [form] = Form.useForm();
 
    /*** apollo ***/
    //查詢場景下拉選單
    const { data:sceneData } = useQuery(GET_SCENE);

    //查詢太陽能下拉選單
    const { data:pvData } = useQuery(GET_PV,{
        variables: { sceneType: sceneType },
    });

    //查詢負載下拉選單
    const { data:loadData } = useQuery(GET_LOAD,{
        variables: { sceneType: sceneType },
    });

    //查詢目標下拉選單
    const { data:targetData } = useQuery(GET_TARGET,{
        variables: { sceneType: sceneType },
    });

    //查詢儲能下拉選單
    const { data:essData } = useQuery(GET_ESS,{
        variables: { sceneType: sceneType, loadType: loadType, pvType: pvType },
    });

    //查詢 vpp id
    const { data:vppData } = useQuery(GET_VPP,{
        variables: { sceneType: dataControl.vppIds?.sceneType, loadType: dataControl.vppIds?.sceneLoad, pvType: dataControl.vppIds?.scenePv, essType: dataControl.vppIds?.sceneEss, targetType: dataControl.vppIds?.sceneTarget },
    });

    /*** 打API 要回panel數據 ***/

    // 1.查詢PV預報資料
    const { data:pvPredict } = useQuery(GET_PV_PREDICT,{
        variables: { objectIDs: dataControl.vppData?.pvID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 2.查詢PV監控資料
    const { data:pvMonitor } = useQuery(GET_PV_MONITOR,{
        variables: { objectIDs: dataControl.vppData?.pvID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 3.查詢負載預報資料
    const { data:loadPredict } = useQuery(GET_LOAD_PREDICT,{
        variables: { objectIDs: dataControl.vppData?.loadID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 4.查詢負載預報資料
    const { data:loadMonitor } = useQuery(GET_LOAD_MONITOR,{
        variables: { objectIDs: dataControl.vppData?.loadID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 5.查詢調度目標值資料
    const { data:essPredict } = useQuery(GET_ESS_PREDICT,{
        variables: { objectIDs: dataControl.vppData?.essID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 6.查詢ESS監控資料
    const { data:essMonitor } = useQuery(GET_ESS_MONITOR,{
        variables: { objectIDs: dataControl.vppData?.essID, start: (dataControl.vppData?.date+" 00:00"), end: (addDay(dataControl.vppData?.date)+" 00:00") },
    });

    // 7.查詢虛擬電網的指標資料(RE, 灰電用量, 綠電用量, 調度達成率, 收益, 花費)
    const { data:vppTarget } = useQuery(GET_VPP_TARGET,{
        variables: { vppIDs: dataControl.vppData?.vppID },
    });
    
    // 8.查詢虛擬電網的預期資料(RE, 收益, 花費)
    const { data:vppExpect } = useQuery(GET_VPP_EXPECT,{
        variables: { vppIDs: dataControl.vppData?.vppID },
    });

    // 9.查詢虛擬電網的預期資料(RE, 收益, 花費)
    const { data:vppContract } = useQuery(GET_VPP_CONTRACT,{
        variables: { vppIDs: dataControl.vppData?.vppID },
    });

    // 表單完成
    const onFinish = (values) => {
        dataControl.changeVppIds(values); //更新vpp id (用來查詢vpp的日期、型號)      
        startProgram();        
    };

    // 表單失敗
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    //表單內value改變事件
    const formValueChange = (changedValues, allValues) => {   
        const key = Object.keys(changedValues)[0];
        if(key === 'sceneType') {
            setSceneType(changedValues.sceneType);
            form.resetFields(["scenePv","sceneLoad","sceneEss","sceneTarget"]);
            setIsShow('');
            setPvType('');
            setLoadType('');
            setEssItem(null);
            if(changedValues.sceneType === 3){
                form.setFieldValue('sceneLoad','null');
                setLoadType('null');
                setIsShow('none');         
            }
        }
        if(key === 'scenePv') {
            setPvType(changedValues.scenePv);
            if(allValues.sceneEss !== 'null') form.resetFields(["sceneEss"]);
        }
        if(key === 'sceneLoad') {
            setLoadType(changedValues.sceneLoad);
            if(allValues.sceneEss !== 'null') form.resetFields(["sceneEss"]);
        }
    }

    //執行模擬
    const startProgram = () =>{            
        timeControl.changeStart(true);
        timeControl.changeTime(10);
        timeControl.changeIsRunning(false);
    }

    //清空資料
    const clearData = () => {
        setPvItem(null);
        setLoadItem(null);
        setEssItem(null);
        setTargetItem(null);
        setSceneType(0);
        setPvType('');
        setLoadType('');
        setIsShow('');
        dataControl.changeVppIds(null);
        dataControl.changeVppData(null);
        dataControl.changePvPredict(null);
        dataControl.changePvMonitor(null);
        dataControl.changeLoadPredict(null);
        dataControl.changeLoadMonitor(null);
        dataControl.changeEssPredict(null);
        dataControl.changeEssMonitor(null);
        dataControl.changeVppTarget(null);
        dataControl.changeVppExpect(null);
    }

    //重製按鈕
    const resetProgram = () => {
        timeControl.changeIsRunning(false);
        timeControl.changeTime(10);
        timeControl.changeStart(false);
        form.resetFields(); 
        clearData();//清除資料
    }

    //漢堡按鈕
    const phoneClick = () => {
        setMenuVisible(!menuVisible);
    };
    
    //下拉選單 API useeffect
    useEffect(()=>{        
        if(sceneData){
            setSceneItem(sceneData); //用電場景
        }
        if(sceneType){ //太陽能、負載、目標
            setPvItem(pvData);
            setLoadItem(loadData);
            setTargetItem(targetData);
        }
        if(sceneType && pvType && loadType){
            setEssItem(essData); //儲能                       
        }         
    },[sceneData,pvData,loadData,targetData,essData,sceneType,pvType,loadType])

    //資料放到context useeffect
    useEffect(()=>{
        if(vppData) dataControl.changeVppData(vppData.sceneVpp.data); //vpp
        if(pvPredict) dataControl.changePvPredict(pvPredict.pvPredictData.data);   
        if(pvMonitor) dataControl.changePvMonitor(pvMonitor.pvMonitorData.data);   
        if(loadPredict) dataControl.changeLoadPredict(loadPredict.loadPredictData.data); 
        if(loadMonitor) dataControl.changeLoadMonitor(loadMonitor.loadMonitorData.data); 
        if(essPredict) dataControl.changeEssPredict(essPredict.predictEssTarget.data); 
        if(essMonitor) dataControl.changeEssMonitor(essMonitor.essMonitorData.data); 
        if(vppTarget) dataControl.changeVppTarget(vppTarget.vppPowerTargetData.data); 
        if(vppExpect) dataControl.changeVppExpect(vppExpect.vppPowerExpectData.data);
        if(vppContract) dataControl.changeVppContract(vppContract.vppContractData.data);
        if(vppData && pvMonitor && vppTarget && vppExpect && essMonitor){
            console.log(dataControl);     
        }      
    },[vppData,pvPredict,pvMonitor,loadPredict,loadMonitor,essPredict,essMonitor,vppTarget,vppExpect,vppContract])

    return (    
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <div
                    className={menuVisible ?'header-mobile-menu open':'header-mobile-menu'}
                    onClick={() => {
                        phoneClick();
                    }}
                >
                    <em />
                    <em />
            </div> 
            <div className='App-header-menu-wrap'>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onValuesChange={formValueChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}  
                >
                    <div className='App-header-menu'>
                        <div className='App-header-menu-select'>
                            <Form.Item name="sceneType" hasFeedback rules={[
                                {
                                    required: true,
                                    message: '請輸入用電場景',
                                },
                            ]}>
                                <Select placeholder='用電場景' style={{ width: 220 }} disabled={ timeControl.start ? true : false }>                    
                                    {sceneItem && sceneItem.sceneItem.data !== null ? sceneItem.sceneItem.data.map(item =>{
                                        return <Option value={item.sceneType} key={item.sceneType}>{item.sceneLabel}</Option>
                                    }):''}
                                </Select>
                            </Form.Item>
                        </div>                       
                        <div className='App-header-menu-select' style={{display: isShow}}>
                            <Form.Item name="sceneLoad" hasFeedback rules={[
                                {
                                    required: true,
                                    message: '請輸入用電負載',
                                },
                            ]}>
                                <Select placeholder="用電負載" style={{ width: 155 }} disabled={ timeControl.start ? true : false }>
                                    {loadItem && loadItem.sceneLoadItem.data[0] !== null ? loadItem.sceneLoadItem.data.map(item =>{
                                        return <Option value={item.loadID} key={item.loadID}>{item.loadLabel}</Option>
                                    }):''}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='App-header-menu-select'>
                            <Form.Item name="scenePv" hasFeedback rules={[
                                {
                                    required: true,
                                    message: '請輸入太陽能設備',
                                },
                            ]}>
                                <Select placeholder='太陽能設備' style={{ width: 205 }} disabled={ timeControl.start ? true : false }>
                                    {pvItem && pvItem.scenePvItem.data[0] !== null ? pvItem.scenePvItem.data.map(item =>{
                                        return <Option value={item.pvID} key={item.pvID}>{item.pvLabel}</Option>
                                    }):''}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='App-header-menu-select'>
                            <Form.Item name="sceneEss" hasFeedback rules={[
                                {
                                    required: true,
                                    message: '請輸入儲能設備',
                                },
                            ]}>
                                <Select placeholder="儲能設備" style={{ width: 205 }} disabled={ timeControl.start ? true : false }>
                                    {essItem && essItem.sceneEssItem.data[0] !== null ? essItem.sceneEssItem.data.map(item =>{
                                        return <Option value={item.essID} key={item.essID}>{item.essLabel}</Option>
                                    }):''}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='App-header-menu-select'>
                            <Form.Item name="sceneTarget" hasFeedback rules={[
                                {
                                    required: true,
                                    message: '請輸入場景目標',
                                },
                            ]}>
                                <Select placeholder="場景目標" style={{ width: 120 }} disabled={ timeControl.start ? true : false }>
                                    {targetItem && targetItem.sceneTargetItem.data[0] !== null ? targetItem.sceneTargetItem.data.map(item =>{
                                        return <Option value={item.targetID} key={item.targetID}>{item.targetLabel}</Option>
                                    }):''}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>                
                    {timeControl.start ? '' :<div className='App-header-button'><Button type="primary" htmlType="submit">執行模擬</Button></div>}
                
                </Form>
            </div>
            {timeControl.start ? <div className='App-header-button reset-button'><Button type="primary" onClick={resetProgram}>重置模擬</Button></div>:''}
            
        </header>
    )
   
})

export default Header;