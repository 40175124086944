import React, { useContext } from 'react';
import { Col } from 'antd';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import { pvData1, pvData2 } from '../pvdata/pvData';
import { maxTick, CalcTaipower, objTimeChangeToMinutes } from '../fuctionTool/functionTool';

const SmallPanel = () => {
    // provider
    const {time} = useContext(TimeContext);
    const {vppTarget, pvMonitor, vppData, essMonitor, loadMonitor, vppContract}  = useContext(DataContext);  
    
    // 時間節點
    const index = Math.floor(time/10);
    const timeIndex = index < 96 ? index-1 : 95;
    const pvTimeIndex = index <= 20 ? -1 : index > 80 ? 59 : index-21;

    /** 合成台電資料 **/
    const pvMonitor_new = pvMonitor && [...pvData1, ...objTimeChangeToMinutes(pvMonitor), ...pvData2];
    const loadMonitor_new = loadMonitor && objTimeChangeToMinutes(loadMonitor);
    const essMonitor_new = essMonitor && objTimeChangeToMinutes(essMonitor);
    const taiCharts = CalcTaipower(pvMonitor_new,loadMonitor_new,essMonitor_new);
    /** /合成台電資料 **/

    const modeTransfer = (
        <>  
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>總用電負載</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number primary-color'>{loadMonitor && loadMonitor[timeIndex] ? loadMonitor[timeIndex].cumulationPowerKWh.toFixed(2) : 0}</span><span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>太陽能發電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{pvMonitor && pvMonitor[pvTimeIndex] ? pvMonitor[pvTimeIndex].cumulationPowerKWh.toFixed(2) : 0 }</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>太陽能-實際使用</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].green.toFixed(2) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>         
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>台電主網輸入</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number primary-color'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].gray.toFixed(2) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能-充電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{essMonitor && essMonitor[timeIndex] ? Math.abs(essMonitor[timeIndex].cumulationChargeKWh.toFixed(2)) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能-放電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{essMonitor && essMonitor[timeIndex] ? essMonitor[timeIndex].cumulationDischargeKWh.toFixed(2) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>           
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>當日最大需量 / 契約容量</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{maxTick(taiCharts,timeIndex)}/{vppContract && vppContract[timeIndex] ? Math.ceil(vppContract[timeIndex]?.loadDemand,1) : 0}</span> <span>kW</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>RE-綠電比例</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{vppTarget && vppTarget[timeIndex] ? (vppTarget[timeIndex].re * 100).toFixed(2) : 0}</span> <span>%</span>
                        </div>
                    </div>
                </div>
            </Col>   
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>估算電費</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{vppTarget && vppTarget[timeIndex] && vppTarget[timeIndex].monitorCost.toFixed(2)}</span> <span>TWD</span>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    );

    const essMode = (
        <>  
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>太陽能發電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{pvMonitor && pvMonitor[pvTimeIndex] ? pvMonitor[pvTimeIndex].cumulationPowerKWh.toFixed(2) : 0 }</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能-充電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{essMonitor && essMonitor[timeIndex] ? Math.abs(essMonitor[timeIndex].cumulationChargeKWh.toFixed(2)) : 0 }</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能-放電</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{essMonitor && essMonitor[timeIndex] ? essMonitor[timeIndex].cumulationDischargeKWh.toFixed(2) : 0 }</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>太陽能躉售</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].pvFitPower.toFixed(2) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能躉售</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].essFitPower.toFixed(2) : 0}</span> <span>kWh</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>調度達成率</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number'>{vppTarget && vppTarget[timeIndex] ? (vppTarget[timeIndex].essAchieveRate * 100).toFixed(2) : 0 }</span> <span>%</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>太陽能收益</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].pvFitIncome.toFixed(2) : 0}</span> <span>TWD</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>儲能收益</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].essFitIncome.toFixed(2) : 0}</span> <span>TWD</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className='App-box'>
                    <div className='App-box-header'>
                        <h3>總收益</h3>
                    </div>
                    <div className='App-box-content text-center'>
                        <div className='number-wrap'>
                            <span className='number fourth-color'>{vppTarget && vppTarget[timeIndex] ? vppTarget[timeIndex].monitorIncome.toFixed(2) : 0}</span> <span>TWD</span>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )

    return vppData?.essID === 'T7030ESS4' || vppData?.essID === 'T7030ESS5' || vppData?.essID === 'T7030ESS6' ? essMode:modeTransfer;
}

export default SmallPanel