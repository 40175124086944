import { LoadingOutlined } from '@ant-design/icons';

//加0
export const addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
}

//繪製圖表
export const drawCharts = (data,time) => {
    const newData = data.filter((item,index)=>{
      return index < Math.floor(time/10);
    })
    return newData;
}

//繪製圖表2 for 有預測圖表
export const drawCharts2 = (data,time,name) => {
    const indexTime = Math.floor(time/10);
    const newData = data.map((item,index)=>{
        if(index >= indexTime){
            return {...item,[name]:undefined};
        }else{
            return item;
        }        
    })
    return newData;
}

//繪製圖表3 for ess
export const drawCharts3 = (data,time,name1,name2) => {
    const indexTime = Math.floor(time/10);
    const newData = data.map((item,index)=>{
        if(index >= indexTime){
            return {...item,[name1]:undefined,[name2]:undefined};
        }else{
            return item;
        }        
    })
    return newData;
}

//轉換時、分
export const formatTime = (current_datetime)=>{
    let formatted_time = addZero(current_datetime.getHours()) + ":" + addZero(current_datetime.getMinutes());
    return formatted_time;
}

//轉換分
export const formatMinutes = (current_datetime)=>{
    let formatted_minutes = (current_datetime.getHours()*60) + current_datetime.getMinutes();
    if(formatted_minutes === 0) return 1440;
    return formatted_minutes;
}

//物件修改時間返回物件function
export const objTimeChange = (arr) => {
    const newArr = [];
    if(arr){
        arr.forEach((item,index,arr) => {
            newArr[index] = {...item, time:formatTime(new Date(item.time))} 
        })
    }else{
        return
    }    
    return newArr;
}

//物件修改時間轉換成分鐘
export const objTimeChangeToMinutes = (arr) => {
    const newArr = [];
    if(arr){
        arr.forEach((item,index,arr) => {
            newArr[index] = {...item, time:formatMinutes(new Date(item.time))} 
        })
    }else{
        return
    }    
    return newArr;
}

//轉換日期
export const formatDate = (current_datetime)=>{
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate();
    return formatted_date;
}

//加一天
export const addDay = (date) =>{
    let dateTime = new Date(date);
    dateTime = dateTime.setDate(dateTime.getDate()+1);
    dateTime = formatDate(new Date(dateTime));
    return dateTime;
}

//合併1個資料
export const mergeCharts = (arr,name) =>{
    if(arr && arr.length > 0){
        const newArr = [];
        arr.forEach((item,index) => {
            newArr[index] = {
                [name]: parseFloat(item.powerKW.toFixed(2)),
                time: item.time,
            }
        })
        return newArr;
    }else{
        return;
    }        
}

//合併2個資料
export const merge2Charts = (arr1,arr2,name1,name2) =>{
    if(arr1 && arr2 && arr1.length > 0 && arr2.length > 0){
        const newArr = [];
        arr1.forEach((item,index) => {
            newArr[index] = {
                [name1]: parseFloat(item.powerKW.toFixed(2)),
                [name2]: parseFloat(arr2[index].powerKW.toFixed(2)),
                time: item.time,
            }
        })
        return newArr;
    }else{
        return;
    }        
}

//合併3個資料+台電
export const merge3Charts = (pv,load,ess,pvname,loadname,essname) =>{
    if(pv && pv.length > 0 ){
        const newArr = [];
        pv.forEach((item,index) => {
            newArr[index] = {
                [pvname]: parseFloat(item.powerKW.toFixed(2)),
                [loadname]: load === undefined || load === null ? 0 : parseFloat(load[index]?.powerKW.toFixed(2)),
                [essname]: ess === undefined || ess === null ? 0 : parseFloat(ess[index]?.powerKW.toFixed(2)),
                taipower: load !== undefined && load !== null ? (load[index].powerKW - item.powerKW - (ess !== undefined && ess !== null ? parseFloat(ess[index]?.powerKW):0)).toFixed(2) : ess !== undefined && ess !== null ? (-item.powerKW - parseFloat(ess[index]?.powerKW)).toFixed(2) : item.powerKW.toFixed(2),
                time: item.time,
            }
        })
        return newArr;
    }else{
        return;
    }        
}

//合併3個ess資料
export const mergeEssCharts = (ess_monitor,ess_predict) =>{
    if(ess_monitor && ess_monitor.length > 0 ){
        const newArr = [];
        ess_monitor.forEach((item,index) => {
            newArr[index] = {
                essMonitor: parseFloat(item.powerKW.toFixed(2)),
                essPredict: ess_predict === undefined || ess_predict === null ? 0 : parseFloat(ess_predict[index]?.powerKW.toFixed(2)),
                soc: item.soc,                
                time: item.time,
            }
        })
        return newArr;
    }else{
        return;
    }        
}


//合成台電資料
export const CalcTaipower = (pv,load,ess) =>{
    if(pv && pv.length > 0 ){
        const newArr = [];
        pv.forEach((item,index) => {
            newArr[index] = {
                powerKW: load !== undefined && load !== null ? parseFloat(load[index].powerKW - item.powerKW - (ess !== undefined && ess !== null ? parseFloat(ess[index]?.powerKW):0).toFixed(2)) : ess !== undefined && ess !== null ? parseFloat(-item.powerKW - parseFloat(ess[index]?.powerKW).toFixed(2)) : parseFloat(item.powerKW.toFixed(2)),
                time: item.time,
            }
        })
        return newArr;
    }else{
        return;
    }        
}

//計算1個線稿的最高值
export const maxNum1 = (arr,key) =>{
    let max = 0;
    if(arr && arr.length > 0){
        max = Math.max(...arr.map(a => a[key])); 
    }
    return Math.ceil10(max,1);    
};

//計算2個線稿的最高值
export const maxNum = (arr,key1,key2) =>{
    let key1max = 0;
    let key2max = 0;
    let max = 0;
    if(arr && arr.length > 0){
        key1max = Math.max(...arr.map(a => a[key1])); 
        key2max = Math.max(...arr.map(a => a[key2])); 
    }
    max = Math.max(key1max,key2max);
    return Math.round10(Math.round(max),1) +10;    
};

//計算4個線稿的最高值
export const maxNum4 = (arr,key1,key2,key3,key4) =>{
    let key1max = 0;
    let key2max = 0;
    let key3max = 0;
    let key4max = 0;
    let max = 0;
    if(arr && arr.length > 0){
        key1max = Math.max(...arr.map(a => a[key1])); 
        key2max = Math.max(...arr.map(a => a[key2]));
        key3max = Math.max(...arr.map(a => a[key3])); 
        key4max = Math.max(...arr.map(a => a[key4]));  
    }
    max = Math.max(key1max,key2max,key3max,key4max);
    return Math.round10(Math.round(max),1) +10;    
};

//計算2個線稿的最低值
export const minNum = (arr,key1,key2) =>{
    let key1min = 0;
    let key2min = 0;
    let min = 0;
    if(arr && arr.length > 0){
        key1min = Math.min(...arr.map(a => a[key1])); 
        key2min = Math.min(...arr.map(a => a[key2])); 
    }
    min = Math.min(key1min,key2min);
    return Math.round10(Math.round(min),1) -10;    
};

//計算4個線稿的最低值
export const minNum4 = (arr,key1,key2,key3,key4) =>{
    let key1min = 0;
    let key2min = 0;
    let key3min = 0;
    let key4min = 0;
    let min = 0;
    if(arr && arr.length > 0){
        key1min = Math.min(...arr.map(a => a[key1])); 
        key2min = Math.min(...arr.map(a => a[key2])); 
        key3min = Math.min(...arr.map(a => a[key3])); 
        key4min = Math.min(...arr.map(a => a[key4])); 
    }
    min = Math.min(key1min,key2min,key3min,key4min);
    return Math.round10(Math.round(min),1) -10;    
};


// 閉包含數
(function() {
    /**
     * Decimal adjustment of a number.
     *
     * @param {String}  type  The type of adjustment.
     * @param {Number}  value The number.
     * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number} The adjusted value.
     */
    function decimalAdjust(type, value, exp) {
      // If the exp is undefined or zero...
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // If the value is not a number or the exp is not an integer...
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      // Shift
      value = value.toString().split('e');
      value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      // Shift back
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }
  
    // Decimal round
    if (!Math.round10) {
      Math.round10 = function(value, exp) {
        return decimalAdjust('round', value, exp);
      };
    }
    // Decimal floor
    if (!Math.floor10) {
      Math.floor10 = function(value, exp) {
        return decimalAdjust('floor', value, exp);
      };
    }
    // Decimal ceil
    if (!Math.ceil10) {
      Math.ceil10 = function(value, exp) {
        return decimalAdjust('ceil', value, exp);
      };
    }
  })();
  

  
// legend文字format
export const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color:'rgba(255,255,255,.7)' }}>{value === "pvMonitor" ? "太陽能" : value === "loadMonitor" ? "負載" : value === "essMonitor" ? "儲能" : "台電" }</span>;
};

// labelFormatter
export const labelFormatter = (val) =>{
   return  addZero(Math.floor(val/60)) + ':' + addZero(val%60);
}

// renderTipFormatter
export const renderTipFormatter = (value, name, props) => {
    return [(name === "soc" ? value * 100 + "%" : value), (name === "pvMonitor" ? "太陽能" : name === "loadMonitor" ? "負載" : name === "essMonitor" ? "儲能" : name === "pvPredict" ? "太陽能預測" : name === "loadPredict" ? "負載預測" : name === "essPredict" ? "調度目標" : name === "cost" ? "時間電價" : name === "soc" ? "SOC" : "台電")]
}

//tickFormatter
export const tickFormatter = (num) => {
    let hour = Math.floor(num / 60);
    let minute = num % 60;
    if(hour < 10){
        hour = `0${hour}`
    }
    if(minute < 10){
        minute = `0${minute}`
    }
    return `${hour}:${minute}`;     
}

// 計算契約容量(當前負載最高值)
export const maxTick = (load,time) =>{
    if(load){
        const data =  load.filter((item,index) => {
            return index <= time;
        })
        const maxTick = data.reduce((pre,cur)=>{
            return Math.max(pre, cur.powerKW).toFixed(2);      
        },0)
        return maxTick;    
    }
}

// loading spin
export const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 55,
      }}
      spin
    />
  );

// X軸時間點
export const timeArr = [15,120,240,360,480,600,720,840,960,1080,1200,1320,1440];

//時間電價時間點
export const timeCostArr = [450,600,720,780,1020,1350];