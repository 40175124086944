export const pvPredictData1 =[
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 15,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 30,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 45,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 60,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 75,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 90,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 105,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 120,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 135,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 150,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 165,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 180,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 195,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 210,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 225,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 240,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 255,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 270,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 285,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 300,
        __typename: "PredictData"
    },
]

export const pvPredictData2 = [
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1215,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1230,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1245,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1260,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1275,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1290,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1305,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1320,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1335,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1350,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1365,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1380,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1395,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1410,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1425,
        __typename: "PredictData"
    },
    {
        cumulationPowerKWh: 0,
        objectID: "",
        powerKW: 0,
        powerKWh: 0,
        time: 1440,
        __typename: "PredictData"
    },
]