import React, { useContext } from 'react';
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip } from 'recharts';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import { drawCharts2, objTimeChangeToMinutes, merge2Charts, maxNum, timeArr, labelFormatter, renderTipFormatter, tickFormatter } from '../fuctionTool/functionTool';

const LoadChart = () => {
    // provider
    const {time} = useContext(TimeContext);
    const {loadMonitor, loadPredict}  = useContext(DataContext);

    const loadMonitor_new = loadMonitor && objTimeChangeToMinutes(loadMonitor);
    const loadPredict_new = loadPredict && objTimeChangeToMinutes(loadPredict);

    const mergeLoad = merge2Charts(loadMonitor_new,loadPredict_new,"loadMonitor","loadPredict");  //合併圖表
    const max = maxNum(mergeLoad,"loadMonitor","loadPredict"); //計算最高值

    return (
        <ResponsiveContainer width="100%" height={120}>
            <ComposedChart  width={200} height={120} data={mergeLoad && drawCharts2(mergeLoad,time,"loadMonitor")} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="loadMonitor" fill="#009CCD" stroke="#009CCD" animationDuration={200}/>
                <Line dot={false} strokeDasharray="3 3" strokeWidth={2} animationDuration={200} type="monotone" dataKey="loadPredict" stroke="#00cda8" />
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis type="number" domain={[0,max]}/>
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default LoadChart
