import React, { useState, createContext } from 'react';

// time context
export const DataContext = createContext();

// time provider
export default function DataProvider({children}){      
    const [vppIds, setVppIds] = useState(); //虛擬電廠ID object
    const [vppData, setVppData] = useState(); //虛擬電廠資料 object
    const [pvPredict, setPvPredict] = useState(); //PV預報資料
    const [pvMonitor, setPvMonitor] = useState(); //PV監控資料
    const [loadPredict, setLoadPredict] = useState(); //負載預報資料
    const [loadMonitor, setLoadMonitor] = useState(); //負載監控資料
    const [essPredict, setEssPredict] = useState(); //調度目標值資料
    const [essMonitor, setEssMonitor] = useState(); //ESS監控資料
    const [vppTarget, setVppTarget] = useState(); //虛擬電網的指標資料
    const [vppExpect, setVppExpect] = useState(); //虛擬電網的預期資料
    const [vppContract, setVppContract] = useState(); //合約資料

    const changeVppIds = (value) => setVppIds(value); //修改VPP ID
    const changeVppData = (value) => setVppData(value); //修改VPP DATA
    const changePvPredict = (value) => setPvPredict(value); //修改PV預報資料
    const changePvMonitor = (value) => setPvMonitor(value); //修改PV監控資料
    const changeLoadPredict = (value) => setLoadPredict(value); //修改負載預報資料
    const changeLoadMonitor = (value) => setLoadMonitor(value); //修改負載監控資料
    const changeEssPredict = (value) => setEssPredict(value); //修改調度目標值資料
    const changeEssMonitor = (value) => setEssMonitor(value); //修改ESS監控資料
    const changeVppTarget = (value) => setVppTarget(value); //修改虛擬電網的指標資料
    const changeVppExpect = (value) => setVppExpect(value); //修改虛擬電網的預期資料
    const changeVppContract = (value) => setVppContract(value); //修改虛擬電網的預期資料

    const [essReverse, setEssReverse] = useState(false);
    const [essZero, setEssZero] = useState(false);
    const changeEssReverse = (value) => setEssReverse(value); 
    const changeEssZero = (value) => setEssZero(value); 
    const [taiPowerReverse, setTaiPwoerReverse] = useState(false);
    const changeTaiPwoerReverse = (value) => setTaiPwoerReverse(value); 
    const [taiPowerZero, setTaiPwoerZero] = useState(false);
    const changeTaiPwoerZero = (value) => setTaiPwoerZero(value); 
    const [pvZero, setPvZero] = useState(false);
    const changePvZero = (value) => setPvZero(value); 

    const defaultValue = {
        vppIds,
        vppData,
        pvPredict,
        pvMonitor,
        loadPredict,
        loadMonitor,
        essPredict,
        essMonitor,
        vppTarget,
        vppExpect,
        vppContract,
        changeVppIds,
        changeVppData,
        changePvPredict,
        changePvMonitor,
        changeLoadPredict,
        changeLoadMonitor,
        changeEssPredict,
        changeEssMonitor,
        changeVppTarget,
        changeVppExpect,
        changeVppContract,

        essReverse,
        essZero,
        changeEssReverse,
        changeEssZero,
        taiPowerReverse,
        taiPowerZero,
        changeTaiPwoerReverse,
        changeTaiPwoerZero,
        pvZero,
        changePvZero
    }
    
    return (
        <DataContext.Provider value={defaultValue}>
           {children}           
        </DataContext.Provider>
    )                               
}