import React from 'react';
import { ComposedChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip, ReferenceLine } from 'recharts';
import { timeCost } from '../pvdata/timeCost';
import { maxNum1, timeArr, labelFormatter, renderTipFormatter, tickFormatter, timeCostArr } from '../fuctionTool/functionTool';

const LoadChart = () => {
    // provider
    const max = maxNum1(timeCost,"cost"); //計算最高值

    return (
        <ResponsiveContainer width="100%" height={120}>
            <ComposedChart  width={200} height={120} data={timeCost} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="cost" fill="#009CCD" stroke="#009CCD" animationDuration={200}/>                
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis type="number" domain={[0,max]}/>
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                {timeCostArr.map(item => <ReferenceLine x={item} stroke="rgba(255,255,255,0.2)" strokeDasharray="3 3" key={item}/>)}
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default LoadChart
