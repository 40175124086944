import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import TimeProvider from './context/TimeProvider.js';
import DataProvider from './context/DataProvider.js';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://chqqqd3bnnbp7gvnkan44dogie.appsync-api.ap-northeast-1.amazonaws.com/graphql',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-api-key": "da2-3ymzoepxwvcchebmo4w5oqdeam"
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <TimeProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </TimeProvider>  
  </ApolloProvider>  
);

reportWebVitals();
