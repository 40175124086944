import React, { useContext } from 'react';
import { Spin, Row, Col } from 'antd';
import useInterval from 'use-interval'
import Process from './component/Process';
import Header from './component/Header';
import Footer from './component/Footer';
import SmallPanel from './component/SmallPanel';
import MainChart from './component/MainChart';
import PvChart from './component/PvChart';
import LoadChart from './component/LoadChart';
import EssChart from './component/EssChart';
import TimeCostChart from './component/TimeCostChart';
import EssCostChart from './component/EssCostChart';
import TimeSlider from './component/TimeSlider';
import LoadingData from './component/LoadingData';
import { TimeContext } from './context/TimeProvider';
import { DataContext } from './context/DataProvider';
import { antIcon } from './fuctionTool/functionTool';
import './assets/css/App.less';

// App
function App() {
  // provider
  const timeControl = useContext(TimeContext);
  const dataControl  = useContext(DataContext);

  //啟動計時器
  useInterval(() => {
    if(timeControl.start){
      timeControl.changeTime((time) => time + 1);    
    }      
  }, timeControl.isRunning ? timeControl.runTime : null );
  
  return (    
    <div className="App">      
      <Header />
      {timeControl.start ? '':<LoadingData />}
      {dataControl.pvMonitor && dataControl.essMonitor ? 
      <main className='App-main'>
        {/* 時間控制 */}
        <TimeSlider />
        <Row gutter={[{ xs: 12, sm: 16, md: 16, lg: 16 },{ xs: 12, sm: 16, md: 16, lg: 16 }]}>       
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            {/* main panel */}
            <div className='App-panel'>
              <div className='App-box'>
                <div className='App-box-content flex justify-center align-center'>                
                  <Process />                                      
                </div>
              </div>          
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            {/* panel -9 */}
            <div className='App-panel'>
              <Row gutter={[{ xs: 12, sm: 16, md: 16, lg: 16 },{ xs: 12, sm: 16, md: 16, lg: 16 }]}>
                <SmallPanel />
              </Row>
            </div>    
          </Col>
        </Row>
        
        <Row gutter={[{ xs: 12, sm: 16, md: 16, lg: 16 },{ xs: 12, sm: 16, md: 16, lg: 16 }]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* main-chart */}
              <div className='App-panel'>
                <div className='App-box'>
                  <div className='App-box-content'>
                    <h3>太陽能/負載/儲能/台電</h3>
                    <MainChart />
                  </div>
                </div>          
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* chart-4 */}
              <div className='App-panel'>
                <Row gutter={[{ xs: 12, sm: 16, md: 16, lg: 16 },{ xs: 12, sm: 16, md: 16, lg: 16 }]}>  
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className='App-box'>
                      <div className='App-box-content'>
                        <h3>太陽能監控/預測</h3>
                        <PvChart />
                      </div>
                    </div>
                  </Col>
                  {dataControl.loadMonitor &&
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className='App-box'>
                      <div className='App-box-content'>
                        <h3>負載監控/預測</h3>
                        <LoadChart />
                      </div>
                    </div>  
                  </Col>}
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className='App-box'>
                      <div className='App-box-content'>
                        <h3>儲能監控/調度目標</h3>
                        <EssChart />
                      </div>
                    </div>
                  </Col>
                  {dataControl.vppData?.essID !== 'T7030ESS4' && dataControl.vppData?.essID !== 'T7030ESS5' && dataControl.vppData?.essID !== 'T7030ESS6' ? 
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className='App-box'>
                        <div className='App-box-content'>
                          <h3>時間電價</h3>
                          <TimeCostChart />
                        </div>
                      </div> 
                    </Col> : ''}
                  {dataControl.vppData?.essID === 'T7030ESS4' || dataControl.vppData?.essID === 'T7030ESS5' || dataControl.vppData?.essID === 'T7030ESS6' ? 
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className='App-box'>
                        <div className='App-box-content'>
                          <h3>躉售價格</h3>
                          <EssCostChart/>
                        </div>
                      </div> 
                    </Col> : ''}
                </Row>    
              </div>
            </Col>           
        </Row>
      </main> : <div className='App-spin'><Spin indicator={antIcon} /></div>}    
      <Footer />
    </div>
    )
}

export default App;
