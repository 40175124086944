export const timeCost =[
    {
        cost: 1.8,
        time: 15,
    },
    {
        cost: 1.8,
        time: 30,
    },
    {
        cost: 1.8,
        time: 45,
    },
    {
        cost: 1.8,
        time: 60,
    },
    {
        cost: 1.8,
        time: 75,
    },
    {
        cost: 1.8,
        time: 90,
    },
    {
        cost: 1.8,
        time: 105,
    },
    {
        cost: 1.8,
        time: 120,
    },
    {
        cost: 1.8,
        time: 135,
    },
    {
        cost: 1.8,
        time: 150,
    },
    {
        cost: 1.8,
        time: 165,
    },
    {
        cost: 1.8,
        time: 180,
    },
    {
        cost: 1.8,
        time: 195,
    },
    {
        cost: 1.8,
        time: 210,
    },
    {
        cost: 1.8,
        time: 225,
    },
    {
        cost: 1.8,
        time: 240,
    },
    {
        cost: 1.8,
        time: 255,
    },
    {
        cost: 1.8,
        time: 270,
    },
    {
        cost: 1.8,
        time: 285,
    },
    {
        cost: 1.8,
        time: 300,
    },
    {
        cost: 1.8,
        time: 315,
    },
    {
        cost: 1.8,
        time: 330,
    },
    {
        cost: 1.8,
        time: 345,
    },
    {
        cost: 1.8,
        time: 360,
    },
    {
        cost: 1.8,
        time: 375,
    },
    {
        cost: 1.8,
        time: 390,
    },
    {
        cost: 1.8,
        time: 405,
    },
    {
        cost: 1.8,
        time: 420,
    },
    {
        cost: 1.8,
        time: 435,
    },
    {
        cost: 1.8,
        time: 450,
    },
    {
        cost: 4.07,
        time: 465,
    },
    {
        cost: 4.07,
        time: 480,
    },
    {
        cost: 4.07,
        time: 495,
    },
    {
        cost: 4.07,
        time: 510,
    },
    {
        cost: 4.07,
        time: 525,
    },
    {
        cost: 4.07,
        time: 540,
    },
    {
        cost: 4.07,
        time: 555,
    },
    {
        cost: 4.07,
        time: 570,
    },
    {
        cost: 4.07,
        time: 585,
    },
    {
        cost: 4.07,
        time: 600,
    },
    {
        cost: 6.2,
        time: 615,
    },
    {
        cost: 6.2,
        time: 630,
    },
    {
        cost: 6.2,
        time: 645,
    },
    {
        cost: 6.2,
        time: 660,
    },
    {
        cost: 6.2,
        time: 675,
    },
    {
        cost: 6.2,
        time: 690,
    },
    {
        cost: 6.2,
        time: 705,
    },
    {
        cost: 4.07,
        time: 720,
    },
    {
        cost: 4.07,
        time: 735,
    },
    {
        cost: 4.07,
        time: 750,
    },
    {
        cost: 4.07,
        time: 765,
    },
    {
        cost: 4.07,
        time: 780,
    },
    {
        cost: 6.2,
        time: 795,
    },
    {
        cost: 6.2,
        time: 810,
    },
    {
        cost: 6.2,
        time: 825,
    },
    {
        cost: 6.2,
        time: 840,
    },
    {
        cost: 6.2,
        time: 855,
    },
    {
        cost: 6.2,
        time: 870,
    },
    {
        cost: 6.2,
        time: 885,
    },
    {
        cost: 6.2,
        time: 900,
    },
    {
        cost: 6.2,
        time: 915,
    },
    {
        cost: 6.2,
        time: 930,
    },
    {
        cost: 6.2,
        time: 945,
    },
    {
        cost: 6.2,
        time: 960,
    },
    {
        cost: 6.2,
        time: 975,
    },
    {
        cost: 6.2,
        time: 990,
    },
    {
        cost: 6.2,
        time: 1005,
    },
    {
        cost: 6.2,
        time: 1020,
    },
    {
        cost: 4.07,
        time: 1035,
    },
    {
        cost: 4.07,
        time: 1050,
    },
    {
        cost: 4.07,
        time: 1065,
    },
    {
        cost: 4.07,
        time: 1080,
    },
    {
        cost: 4.07,
        time: 1095,
    },
    {
        cost: 4.07,
        time: 1110,
    },
    {
        cost: 4.07,
        time: 1125,
    },
    {
        cost: 4.07,
        time: 1140,
    },
    {
        cost: 4.07,
        time: 1155,
    },
    {
        cost: 4.07,
        time: 1170,
    },
    {
        cost: 4.07,
        time: 1185,
    },
    {
        cost: 4.07,
        time: 1200,
    },
    {
        cost: 4.07,
        time: 1215,
    },
    {
        cost: 4.07,
        time: 1230,
    },
    {
        cost: 4.07,
        time: 1245,
    },
    {
        cost: 4.07,
        time: 1260,
    },
    {
        cost: 4.07,
        time: 1275,
    },
    {
        cost: 4.07,
        time: 1290,
    },
    {
        cost: 4.07,
        time: 1305,
    },
    {
        cost: 4.07,
        time: 1320,
    },
    {
        cost: 4.07,
        time: 1335,
    },
    {
        cost: 4.07,
        time: 1350,
    },
    {
        cost: 1.8,
        time: 1365,
    },
    {
        cost: 1.8,
        time: 1380,
    },
    {
        cost: 1.8,
        time: 1395,
    },
    {
        cost: 1.8,
        time: 1410,
    },
    {
        cost: 1.8,
        time: 1425,
    },
    {
        cost: 1.8,
        time: 1440,
    },
]