import loaddata from '../assets/images/loaddata.jpg';
import logo from '../assets/images/logo_white_simulation.svg';

export default function LoadingData (){
    return (<div className='load-data'>
        <div className='load-data-logo'>
            <img src={logo} alt={logo} />
            
        </div>
        <img className='load-data-img' src={loaddata} alt={loaddata} />
    </div>)
}