import React, { useContext } from 'react';
import { Button, Slider } from 'antd';
import Icon, { ForwardOutlined, CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { TimeContext } from '../context/TimeProvider';

//快轉icon
const FastForwardSvg = ()=> (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 12 12">
      <path d="M7.8,5.9L4.4,2.9c-0.1-0.1-0.3,0-0.3,0.2v5.8c0,0.2,0.2,0.3,0.3,0.2l3.4-2.9C7.9,6.1,7.9,5.9,7.8,5.9z M4.1,5.9L0.7,2.9
        c-0.1-0.1-0.3,0-0.3,0.2v5.8c0,0.2,0.2,0.3,0.3,0.2l3.4-2.9c0,0,0.1-0.1,0.1-0.2C4.1,6,4.1,5.9,4.1,5.9z"/>
      <path d="M11.6,5.9L8.2,2.9c-0.1-0.1-0.3,0-0.3,0.2v5.8c0,0.2,0.2,0.3,0.3,0.2l3.4-2.9C11.6,6.1,11.6,5.9,11.6,5.9z"/>
    </svg>
  )
const FastForwardIcon = (props) => <Icon component={FastForwardSvg} {...props} />;

//滾動條 時間點位
const marks = {
    10: '00:15',
    80: '02:00',
    160: '04:00',
    240: '06:00',
    320: '08:00',
    400: '10:00',
    480: '12:00',
    560: '14:00',
    640:'16:00',
    720:'18:00',
    800:'20:00',
    880:'22:00',
    960: {
      style: {
        color: '#fff',
      },
      label: '24:00',
    },
  };

export default function TimeSlider(){
    const timeControl = useContext(TimeContext);
    
    //數字轉時間(時、分)
    const timeTickHandler = () => {
        let hour = Math.floor(timeControl.time*90 / 3600);
        let minute = Math.floor(timeControl.time*90 % 3600 / 60);
        if(hour < 10){
            hour = `0${hour}`
        }
        if(minute < 10){
            minute = `0${minute}`
        }
        if(timeControl.time < 950 && timeControl.time >= 0){     
            return `${hour}:${minute}`; 
        }else{
             
            return `24:00`;
        }    
    }

    // play按鈕
    const runProgram = () =>{
        if(timeControl.time>=0 && timeControl.time<950){
            timeControl.changeIsRunning(true);    
            timeControl.changeRunTime(100); 
        }      
    }

    // 加速按鈕
    const forwardProgram = () =>{
        if(timeControl.time>=0 && timeControl.time<950){    
            timeControl.changeIsRunning(true);         
            if(timeControl.runTime === 75){
                timeControl.changeRunTime(50); 
            }else{
                timeControl.changeRunTime(75);
            }
        }
    }

    //停止按鈕
    const stopProgram = () => {
        timeControl.changeIsRunning(false);  
        timeControl.changeRunTime(100);
    }

    //滾動條 change 事件
    const onChangeSlider = (newValue) => {
        timeControl.changeIsRunning(false); 
        timeControl.changeTime(newValue);
        timeControl.changeRunTime(100);
    }
    
    return (
        <div className='time-zone'>
          <div className='time-text'>時間</div>
          <div className='time-clock'>{ timeTickHandler() }</div>
          <div className='time-speed'>         
              <Button icon={<CaretRightOutlined />} ghost onClick={runProgram} shape="circle" size="large" />
              <Button icon={<PauseOutlined />} ghost onClick={stopProgram} shape="circle" size="large" />
              <Button icon={timeControl.runTime === 50 ? <FastForwardIcon/> : <ForwardOutlined/> } ghost onClick={forwardProgram} shape="circle" size="large" />         
          </div>
          <div className='time-slider'>
            <Slider min={10} max={960} marks={marks} defaultValue={0} value={timeControl.time} onChange={onChangeSlider}/>
          </div>
        </div>
    )
}
