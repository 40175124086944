import React, { useState, createContext, useEffect } from 'react';
import { notification } from 'antd';

// time context
export const TimeContext = createContext();

// 模擬結束時跳出訊息
const openNotification = (placement) => {
    notification.info({
      message: `模擬結束`,
      description:
        '本次模擬已結束。',
      placement,
    });
};

// time provider
export default function TimeProvider({children}){      
    const [time, setTime] = useState(10); //時間 共960秒
    const [runTime, setRunTime] = useState(100); // interval delay time
    const [start, setStart] = useState(false); // 開始程式
    const [isRunning, setIsRunning] = useState(false); // interval delay time (fales or true) 
    const changeTime = (value) => setTime(value);
    const changeStart = (value) => setStart(value);
    const changeRunTime = (value) => setRunTime(value);
    const changeIsRunning = (value) => setIsRunning(value);

    const defaultValue = {
        time,
        changeTime,
        start,
        changeStart,
        runTime,
        changeRunTime,
        isRunning,
        changeIsRunning
    }
    
    useEffect(()=>{
        if( time > 960){
            if(isRunning && time > 0){
                openNotification('top');
                changeIsRunning(false);  
                changeRunTime(100);
            }            
        } 
    })
    
    return (
        <TimeContext.Provider value={defaultValue}>
           {children}           
        </TimeContext.Provider>
    )                               
}