import React, { useContext } from 'react';
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, Tooltip, Legend, ReferenceLine } from 'recharts';
import { TimeContext } from '../context/TimeProvider';
import { DataContext } from '../context/DataProvider';
import { pvData1, pvData2 } from '../pvdata/pvData';
import { drawCharts, objTimeChangeToMinutes, merge3Charts, maxNum4, minNum4, timeArr, renderColorfulLegendText, labelFormatter, renderTipFormatter, tickFormatter,timeCostArr } from '../fuctionTool/functionTool';

const MainChart = () => {
    // provider
    const {time} = useContext(TimeContext);
    const {loadMonitor,essMonitor,pvMonitor,vppData}  = useContext(DataContext);

    const pvMonitor_new = pvMonitor && [...pvData1, ...objTimeChangeToMinutes(pvMonitor), ...pvData2];
    const loadMonitor_new = loadMonitor && objTimeChangeToMinutes(loadMonitor);
    const essMonitor_new = essMonitor && objTimeChangeToMinutes(essMonitor);

    const mergeAll = merge3Charts(pvMonitor_new,loadMonitor_new,essMonitor_new,"pvMonitor","loadMonitor","essMonitor");
    const max = maxNum4(mergeAll,"pvMonitor","loadMonitor","essMonitor","taipower"); //計算最高值
    const min = minNum4(mergeAll,"pvMonitor","loadMonitor","essMonitor","taipower"); //計算最低值
    
    return (
        <ResponsiveContainer width="100%" height={320}>
            <ComposedChart  width={200} height={320} data={mergeAll && drawCharts(mergeAll,time)} margin={{top: 0,right: 0,left: -20,bottom: 0, }}>
                <Area type="monotone" dataKey="pvMonitor" fill="#00cda8" stroke="#00cda8" strokeWidth={2} animationDuration={200}/>
                <Area type="monotone" dataKey="loadMonitor" fill="#009CCD" stroke="#009CCD" strokeWidth={2} animationDuration={200}/>
                <Area type="monotone" dataKey="essMonitor" fill="#fabf35" stroke="#fabf35" strokeWidth={2} animationDuration={200}/>
                <Line dot={false} strokeDasharray="3 3" strokeWidth={2} animationDuration={200} type="monotone" dataKey="taipower" stroke="#69ccdb" />
                <XAxis dataKey="time" type="number" domain={[15,1440]} tickFormatter={tickFormatter} ticks={timeArr}/>
                <YAxis type="number" domain={[min,max]} ticks={[min,min/2,0,max/2,max]}/>
                <Tooltip labelFormatter={labelFormatter} formatter={renderTipFormatter}/>
                <Legend formatter={renderColorfulLegendText}/>
                {vppData?.essID === 'T7030ESS4' || vppData?.essID === 'T7030ESS5' || vppData?.essID === 'T7030ESS6' ? '':
                 timeCostArr.map(item => <ReferenceLine x={item} stroke="rgba(255,255,255,0.2)" strokeDasharray="3 3" key={item}/>)}
                <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default MainChart