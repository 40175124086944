import { gql } from '@apollo/client';

//場景下拉選單query
export const GET_SCENE =gql`query { sceneItem { code msg data { sceneType sceneLabel } } }`; 

//太陽能下拉選單query
export const GET_PV = gql`query WhatEverQuery($sceneType: Int!){ scenePvItem( sceneType: $sceneType ) { code msg data {pvID pvLabel }}}`; 

//負載下拉選單query
export const GET_LOAD = gql`query WhatEverQuery($sceneType: Int!){ sceneLoadItem(sceneType: $sceneType ) { code msg data { loadID loadLabel }}}`; 

//目標下拉選單query
export const GET_TARGET = gql`query WhatEverQuery($sceneType: Int!){ sceneTargetItem(sceneType: $sceneType ) { code msg data { targetID targetLabel}}}`; 

//儲能下拉選單query
export const GET_ESS = gql`query WhatEverQuery($sceneType: Int! $pvType: String! $loadType: String!){ sceneEssItem(sceneType: $sceneType pvID: $pvType loadID: $loadType) { code msg data { essID essLabel }}}`; 

//vpp id
export const GET_VPP = gql`query WhatEverQuery($sceneType: Int! $pvType: String! $loadType: String! $essType: String! $targetType: Int!){ sceneVpp(sceneType: $sceneType pvID: $pvType loadID: $loadType essID: $essType targetID: $targetType) { code msg data { vppID pvID loadID essID date }}}`; 

//查詢PV預報資料
export const GET_PV_PREDICT = gql`query WhatEverQuery($objectIDs: String! $start: String! $end: String!){ 
    pvPredictData(objectIDs: $objectIDs start: $start end: $end) {
      code
      msg
      data {
        objectID
        time
        powerKW
        powerKWh
      }
    }
  }`;

//查詢PV監控資料
export const GET_PV_MONITOR = gql`query WhatEverQuery($objectIDs: String! $start: String! $end: String!){
    pvMonitorData(objectIDs: $objectIDs start: $start end: $end) {
      code
      msg
      data {
        objectID
        time
        powerKW
        powerKWh
        cumulationPowerKWh
      }
    }
}`;

//查詢負載預報資料
export const GET_LOAD_PREDICT = gql`query WhatEverQuery($objectIDs: String! $start: String! $end: String!){
    loadPredictData(objectIDs: $objectIDs start: $start end: $end) {
      code
      msg
      data {
        objectID
        time
        powerKW
        powerKWh
      }
    }
}`;

//查詢負載監控資料
export const GET_LOAD_MONITOR = gql`query WhatEverQuery($objectIDs: String! $start: String! $end: String!){
    loadMonitorData(objectIDs: $objectIDs start: $start end: $end) {
      code
      msg
      data {
        objectID
        time
        powerKW
        powerKWh
        cumulationPowerKWh
      }
    }
}`;

//查詢調度目標值資料
export const GET_ESS_PREDICT = gql`query ($objectIDs: String! $start: String! $end: String!){
    predictEssTarget(objectID: $objectIDs start: $start, end: $end) {
      code
      msg
      data {
        objectID
        time
        powerKW
      }
    }
}`;

//查詢ESS監控資料
export const GET_ESS_MONITOR = gql`query WhatEverQuery($objectIDs: String! $start: String! $end: String!){
    essMonitorData(objectIDs: $objectIDs start: $start end: $end) {
        code
        msg
        data {
          objectID
          time
          powerKW
          powerKWh
          cumulationChargeKWh
          cumulationDischargeKWh
          soc
        }
    }
}`;

//查詢虛擬電網的指標資料(RE, 灰電用量, 綠電用量, 調度達成率, 收益, 花費)
export const GET_VPP_TARGET = gql`query WhatEverQuery($vppIDs: String!){
    vppPowerTargetData(vppIDs: $vppIDs) {
      code
      msg
      data {
        vppID
        time
        re
        gray
        green
        essAchieveRate
        monitorIncome
        monitorCost
        pvFitIncome
        pvFitPower
        essFitIncome
        essFitPower
      }
    }
  }`;

//查詢虛擬電網的預期資料(RE, 收益, 花費)
export const GET_VPP_EXPECT = gql`query WhatEverQuery($vppIDs: String!){
    vppPowerExpectData(vppIDs: $vppIDs) {
      code
      msg
      data {
        vppID
        expectRe
        expectIncome
        expectCost
      }
    }
  }`;

//查詢合約資料
export const GET_VPP_CONTRACT = gql`query WhatEverQuery($vppIDs: String!){
  vppContractData(vppID: $vppIDs) {
    code
    msg
    data {
      vppID
      time
      loadDemand
      fitPrice
    }
  }
}`;
